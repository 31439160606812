import React from "react";
import "../styles/quality.css"; // You can use CSS modules or a CSS-in-JS solution.
import quality from "../assets/images/quality.svg";

const YourComponent = () => {
  return (
    <div className="max-width">
      <section className="digiforma-section">
        <div className="content-container">
          <div className="text-content">
            <h2 className="title">Soyez au top de la démarche qualité</h2>
            <ul className="feature-list">
              <li>
                Mesurez la qualité de vos formations avec des indicateurs de
                performances pertinents
              </li>
              <li>
                Créez vos enquêtes de satisfaction et d’évaluation des
                compétences de vos apprenants
              </li>
              <li>
                Génération d'un rapport qualité global ou par session de
                formation
              </li>
              <li>
                Améliorez votre processus qualité pour être en conformité avec
                les critères Qualiopi
              </li>
            </ul>
            {/* <button className="cta-button">contact</button> */}
          </div>
          <div className="image-content">
            <img src={quality} alt="Quality  Illustration" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default YourComponent;
