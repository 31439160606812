import React from "react";
import "../styles/elearning.css";
import learn from "../assets/images/learn.png";
import { Link } from "react-router-dom";

const ElearningSection = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Enables smooth scrolling
    });
  };
  return (
    <div className="max-width">
      <section className="elearning-section">
        <div className="elearning-section__content-container">
          <div className="elearning-section__image-content">
            <img
              src={learn}
              alt="E-learning Integration Illustration"
              className="elearning-section__illustration"
            />
          </div>
          <div className="elearning-section__text-content">
            <h2 className="elearning-section__title">
              <span>Edoceo </span>Automatisation et digitalisation<br></br>
            </h2>
            <p>
              <span>Edoceo</span> est un centre de formation dynamique qui se distingue par
              sa capacité à <span>digitaliser</span> et <span>automatiser</span> l'intégralité du
              processus de suivi des formateurs et des stagiaires. Grâce à notre
              approche innovante, nous simplifions la gestion des parcours
              pédagogiques, assurant une expérience fluide pour l'ensemble des
              acteurs impliqués. Edoceo met un point d'honneur à utiliser les
              nouvelles technologies pour optimiser l'efficacité, améliorer la
              communication et offrir un suivi personnalisé, permettant ainsi
              aux formateurs et aux apprenants de se concentrer sur ce qui
              compte vraiment : l'apprentissage et la réussite.
            </p>
            <Link to="/formation" onClick={handleScrollToTop}>
              <button className="elearning-section__cta-button">
                Voir la solution
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ElearningSection;
