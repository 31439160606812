// src/components/PdfViewer.jsx
import React from 'react';

const PdfViewer = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const file = queryParams.get('file');

    if (!file) {
        return <div>Error: No file specified!</div>;
    }

    return (
        <div>
            <iframe
                src={file}
                title="PDF Viewer"
                width="100%"
                height="800px"
                style={{ border: 'none' }}
            ></iframe>
        </div>
    );
};

export default PdfViewer;