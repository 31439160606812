import React, { useState } from 'react';
import '../styles/chatbot.css';
import botIcon from '../assets/icons/chatbot.png';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { sender: "bot", text: "Hello ! Comment puis-je vous aider ?" },
  ]);
  const [input, setInput] = useState("");

  const predefinedPrompts = [
    "Quels sont les services que vous offrez ?",
    "Comment puis-je vous contactez ?",
    "Parlez-moi de votre centre.",
  ];

  const handleSend = () => {
    if (input.trim() === "") return;

    const newMessage = { sender: "user", text: input };
    setMessages([...messages, newMessage]);

    setTimeout(() => {
      const botReply = { sender: "bot", text: generateBotReply(input) };
      setMessages((prevMessages) => [...prevMessages, botReply]);
    }, 800);

    setInput("");
  };

  const generateBotReply = (userMessage) => {
    if (userMessage.toLowerCase().includes("services"))
      return "Nous proposons des formations, du conseil et des services de transformation numérique !";
    if (userMessage.toLowerCase().includes("contact"))
      return "Vous pouvez nous contacter à l'adresse support@edoacademy.com.";
    if (userMessage.toLowerCase().includes("centre"))
    return "Edoceo est un centre de formation spécialisé dans le développement des compétences professionnelles dans les domaines de l'aéroportuaire, l’hôtellerie, du marketing et de la gestion et d'autres formations";
  }
  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className="chatbot-toggle-button" onClick={toggleChat}>
        <img src={botIcon} alt="Chatbot Icon" />
      </button>

      {isOpen && (
        <div className="chatbot-container">
          <div className="chatbot-box">
            <div className="chatbot-header">EdoBot</div>
            <div className="chatbot-messages-container">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`chatbot-message ${message.sender === "bot" ? "bot-message" : "user-message"}`}
                >
                  {message.text}
                </div>
              ))}
            </div>
            <div className="chatbot-input-container">
              <input
                className="chatbot-input"
                type="text"
                placeholder="Tapez ou choisissez un message ..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSend()}
              />
              <button className="chatbot-send-button" onClick={handleSend}>
                Send
              </button>
            </div>
            <div className="chatbot-prompts">
              {predefinedPrompts.map((prompt, index) => (
                <button
                  key={index}
                  className="chatbot-send-button"
                  onClick={() => setInput(prompt)}
                >
                  {prompt}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;