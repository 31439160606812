import React from "react";
import "../styles/presentation.css";
import plans from "../assets/images/co-work.png";

const CompanyLaunch = () => {
  // const { t,i18n } = useTranslation('presentation');
  
  return (
    <div className="max-width">
      <div className="company-launch-container">
        <div className="text-section">
          <h1>
           L'Excellence pédagogique
            <br />
          </h1>
          <p>
            Le Centre de Formation <span>Edoceo</span> se distingue par son expertise dans
            des formations variées, adaptées aux besoins actuels du marché. Nos
            programmes couvrent des domaines tels que l'hôtelerie,
            l'aeroportuaire et le management sont animés par des formateurs
            qualifiés, permettant à chacun de développer des compétences solides
            pour réussir professionnellement.
          </p>
          {/* <a href="#" className="button">
            Contact
          </a> */}
        </div>
        <div className="image-section">
          <img src={plans} alt="Team discussing plans" className="team-image" />
        </div>
      </div>
    </div>
  );
};

export default CompanyLaunch;
