import React from 'react'
import HeroLayout from './Herolayout'
import Chatbot from './Chatbot'
import Navbar from './Navbar'
import Presentation from './Presentation'
import  Footer from './Footer'
import Quality from './Quality'
import Elearning from './Elearning'
import Collaborators from './Collaborators'

function HomePage() {
  return (
    <div>
         <Navbar /> 
        <HeroLayout /> 
        <Presentation />
        <Quality />
        <Elearning />
        <Chatbot /> 
         <Footer/> 
    </div>
  )
}

export default HomePage