import React from "react";
import "../styles/formations.css";
import photo from "../assets/icons/blue.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import cardImage1 from "../assets/images/airport.webp";
import cardImage2 from "../assets/images/restau.png";
import cardImage3 from "../assets/images/peche.png";
import cardImage4 from "../assets/images/managers.png";
import cardImage5 from "../assets/images/hotel.webp";
import cardImage6 from "../assets/images/marketing.png";
import { Link } from "react-router-dom";

function Formations() {
  // const pdfs = [
  //   { title: "Sample PDF 1", path: "../../public/pdfs/hotelerie.pdf" },
  // ];
  const trainings = [
    {
      id: 1,
      title:
        "Excellence en Service : Formation pour le Personnel Aéroportuaire",
      description:
        "Formation pratique pour offrir un service aéroportuaire d'excellence et satisfaire chaque passager",
      badge: "Nos formations",
      image: cardImage1,
      pdfPath : "/pdfs/hotelerie.pdf"
    },
    {
      id: 2,
      title:
        "Excellence en Service : Formation pour le Personnel de Restauration",
      description:
        "Formation pratique pour offrir un service en restauration d'excellence et satisfaire chaque client",
      badge: "Nos formations",
      image: cardImage2,
    },
    {
      id: 3,
      title: "Excellence en Service : Formation pour le Personnel de Pêche",
      description:
        "Formation pratique pour offrir un service en pêche d'excellence et satisfaire chaque client",
      badge: "Nos formations",
      image: cardImage3,
    },
    {
      id: 4,
      title: "Excellence en Leadership : Formation pour les Managers",
      description:
        "Formation pratique pour développer un management d'excellence et inspirer chaque membre de l’équipe.",
      badge: "Nos formations",
      image: cardImage4,
    },
    {
      id: 5,
      title: "Excellence en Leadership : Formation pour les hôteliers",
      description:
        "Formation pratique pour offrir un service hôtelier d'excellence et satisfaire chaque client",
      badge: "Nos formations",
      image: cardImage5,
    },
    {
      id: 6,
      title:
        "Excellence en Stratégie : Formation en Marketing, Management et Intelligence Artificielle",
      description:
        "Formation pratique pour maîtriser le marketing assisté par IA et offrir des stratégies innovantes et efficaces",
      badge: "Nos formations",
      image: cardImage6,
    },
  ];
  return (
    <div className="formaWrapper">
      <Navbar />
      <div className="page-container">
        <div className="text-section">
          <div className="title">Nos formations</div>
          <p>
            Toutes nos formations sont certifiantes et qualifiantes, nous avons
            un réseaux de formateurs expérimentés dans leur
            domaine d'expertises.
          </p>
        </div>
        <div className="image-section">
          <div className="circular-image">
            <img src={photo} alt="Formation" />
          </div>
        </div>
      </div>
      <div className="title-section">Notre catalogue</div>
      <div className="trainings-container">
        {trainings.map((training) => (
          <div key={training.id} className="training-card">
            <img
              src={training.image}
              alt={training.title}
              className="training-image"
            />
            <div className="training-content">
              <p className="training-badge">{training.badge}</p>
               <h2 className="training-title">
               <Link
                 to={`/viewer?file=${training.pdfPath}`} // Modify pdfPath if needed
                 style={{ textDecoration: "none", color: "inherit" }}
               >
                 {training.title}
               </Link>
             </h2>            
              <p className="training-description">{training.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Formations;
