import React from "react";
import "../styles/about.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import team from "../assets/icons/team.png";
import exampleImage from "../assets/images/learn.png";

const AboutUs = () => {
  return (
    <div className="contain">
      <div className="about-wrapper">
        <Navbar />
        <div className="container">
          <div className="text-section">
            <div className="title">Qui sommes nous ?</div>
            <div className="subtitle">
              {" "}
              <span>Edoceo</span> Votre avenir, notre mission de formation.
            </div>
            <p>
            Edoceo est un centre de formation spécialisé dans le développement des compétences professionnelles dans les domaines de l’hôtellerie,
             du marketing et de la gestion. Nous croyons en l’importance d’une formation de qualité 
             pour permettre aux professionnels de réaliser leur potentiel et d’exceller dans leurs carrières.
              Notre mission est de fournir des parcours d’apprentissage efficaces et pratiques, 
              adaptés aux besoins du marché actuel.
            </p>
          </div>
          <div className="image-section">
            <img src={team} alt="About Us" />
          </div>
        </div>

        {/*Section 2*/}

        <div className="digiforma-section">
          <div className="digiforma-content">
            <div className="digiforma-image">
              <img src={exampleImage} alt="Illustration" />
            </div>
            <div className="digiforma-text">
              <h2><span>Edoceo</span> Créateur d’Opportunités et de Compétences</h2>
              <p>
              Fondé avec la volonté de transformer la formation professionnelle,
               Edoceo est un centre de formation dynamique et engagé dans le développement des talents
                dans des secteurs clés tels que l’hôtellerie, le marketing et le management. 
                Depuis sa création, Edoceo se distingue par son approche innovante,
                 alliant expertise et pédagogie interactive pour répondre aux besoins d’un marché 
                 en constante évolution.
              {" "}
              </p>
              <p>
              Ce texte met en avant Edoceo en tant que centre de formation innovant et engagé,
               avec un focus sur l’impact concret et la relation de proximité avec ses apprenants
                et le marché.
              </p>
            </div>
          </div>
          <div className="cta-section">
            <p>
              Alors si vous aussi, vous pensez que la formation professionnelle
              mérite un sérieux coup de boost,{" "}
              <a
                href="https://digiforma.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                rejoignez le mouvement Edoceo !
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
