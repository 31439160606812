import React, { useState } from "react";
import "../styles/navbar.css";
import logo from "../assets/images/Edoceo.png";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  // const { t,i18n } = useTranslation('navbar');

  // // Toggle language function
  // const toggleLanguage = () => {
  //   const newLang = i18n.language === 'en' ? 'fr' : 'en';
  //   i18n.changeLanguage(newLang);
  // };
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      {/* Logo on the left */}
      <div className="navbar-brand">
        <Link to="/">
          <img src={logo} alt="edoceo logo" />
        </Link>
      </div>

      {/* Centered menu links */}
      <div className={`navbar-links ${isOpen ? "mobile active" : ""}`}>
        <Link to="/" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>Accueil</span>
          </div>
        </Link>

        <Link to="/about" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>Qui sommes-nous ?</span>
          </div>
        </Link>

        <Link to="/formation" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>Nos formations</span>
          </div>
        </Link>

        <Link to="/contact" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>Contact</span>
          </div>
        </Link>
      </div>

      {/* Connexion button on the right */}
      {/* <div className="navbar-actions">
        <button className="btn-outline" onClick={() => setIsOpen(false)}>
          Connexion
        </button>
      </div>  */}
        {/* <div className="toggle-container">
        <span>EN</span>
        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={i18n.language === 'fr'}
            onChange={toggleLanguage}
          />
          <span className="slider"></span>
        </label>
        <span>FR</span>
      </div> */}

      <div className="hamburger-menu" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;
